//src/redux/library/actions.js
import axios from 'axios'
import { END_POINT } from '../../constant'
import { setNotification } from '../../redux/notification/actions'

const GET_INIT_DATA = 'GET_INIT_DATA'

const getData = (actionType) => {

    return async (dispatch, getState) => {

        try {
            const request = await axios.get(`${END_POINT}data`);
            const initData = request.data
            dispatch(setData(actionType, initData))
        } catch (err) {
            dispatch(setNotification(err.error || "Error server", "error"))
        }
    }
}


const setData = (actionType, data) => {
    return {
        type: GET_INIT_DATA,
        subtype: actionType,
        payload: data
    }
}


export {
    getData,

    GET_INIT_DATA
}
