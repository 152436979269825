//src/routes/index.js

import React from 'react'
import { connect } from 'react-redux'
import { Router, Route, Switch } from 'react-router-dom'
import { history } from './history'
import { resetNotification } from '../redux/notification/actions'
import NotFound from './NotFound'

// Pages
import Login from '../app/pages/login'
import Home from '../app/pages/home'
import Project from '../app/pages/project'
import About from '../app/pages/about'
import Cart from '../app/pages/cart'
import Confirm from '../app/pages/confirm'
import Collection from '../app/pages/collection'
import Product from '../app/pages/product'
import Accessory from '../app/pages/accessory'
import Finishing from '../app/pages/finishing'
import Downloads from '../app/pages/downloads'


// Layout & Component
import Layout from '../app/layout'
import SnackBar from '../lib/snackBar'

const Routes = (props) => {

    //const { locale, notifications, resetNotification, isAuthenticated, user } = props;
    const { locale, notifications, resetNotification, isAuthenticated } = props;

    if (!isAuthenticated) {
        history.push("/login")
        return <Router history={history}>
            <Switch>
                <Route exact path="/login" component={Login} />
                <Route path="*" component={NotFound} />
            </Switch>
        </Router>
    }

    // Redirect if user is client
    // if (user && user.is_client && history.location.pathname === "/") {
    //     history.push("/my-selection")
    // }

    return (
        <div>
            <Router history={history}>

                <Route exact path="/login" component={Login} />
                {
                    isAuthenticated ?
                        <Layout>
                            <SnackBar
                                onResetNotification={resetNotification}
                                notifications={notifications}
                                locale={locale} />

                            <Switch>
                                <Route exact path="/" component={Home} />
                                <Route exact path="/collections" component={Collection} />
                                <Route path="/collections/:id" component={Accessory} />

                                <Route exact path="/projects" component={Project} />
                                <Route exact path="/finishing" component={Finishing} />
                                <Route exact path="/about-us" component={About} />
                                <Route exact path="/products" component={Product} />
                                <Route exact path="/downloads" component={Downloads} />
                                <Route path="/cart" component={Cart} />
                                <Route path="/confirm-cart" component={Confirm} />

                            </Switch>
                        </Layout>
                        : null
                }

            </Router>
        </div>

    )
}

const mapStateToProps = (state) => {
    return {
        locale: {},
        isAuthenticated: state.auth.isAuthenticated,
        notifications: state.notifications,
        user: state.auth.user
    }
}

export default connect(mapStateToProps, { resetNotification })(Routes);
