//src/app/pages/home.js
import React from 'react'
import { withStyles } from '@material-ui/core'
import { Player } from 'video-react';

const styles = theme => ({
    root: {
        backgroundColor: "black",
        backgroundSize: "cover",
        margin: "0 auto",
        display: "flex",
        justifyContent: "center",
        height: "80vh"
    }

})


class Video extends React.Component {

    state = {
        blob: null
    }

    componentDidMount() {
    //    this.getBlob(this.props.video)
    }

    getBlob = (video) => {
        const request = indexedDB.open('catalogue-database', 1);
        const videoId = video.substring(
            video.lastIndexOf("/") + 1,
            video.lastIndexOf(".mp4")
        ).replace("%20", "")
        let blob = null

        request.onsuccess = event => {
            const db = event.target.result;

            const transaction = db.transaction(['videos', 'read']);
            const objectStore = transaction.objectStore('videos');
            const response = objectStore.get(videoId);

            response.onerror = event => {
                console.log('error');
            };

            response.onsuccess = event => {
                console.log(response.result)
                if (response.result) {
                    blob = response.result.blob
                    this.setState({ blob: window.URL.createObjectURL(blob) })
                } else {
                    this.setState({ blob: video })
                }
            }
        }
    }

    render() {
        const classes = this.props;

        return <div className={classes.root}>

            <Player
                playsInline
                //src={this.state.blob}
                src={this.props.video}
                controls
                preload="none"
            />

        </div >

    }

}

export default withStyles(styles)(Video);
