//src/app/cart/cartReducer.js

import {
    ADD_TO_CART,
    REMOVE_FROM_CART,
    EMPTY_CART,
    ADD_EMAIL,
    REMOVE_EMAIL,
    ADD_NOTES,
    LOADING,
    FAILED

} from './actions'

const initialState = {
    isFetching: false,
    isError: false,
    selections: [],
    list: [],
    emails: [],
    notes: null,

}

const cartReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_TO_CART:
            if (state.selections.includes(action.item.id)) {
                return { ...state }
            } else {
                return {
                    ...state,
                    selections: [...state.selections, action.item.id],
                    list: [...state.list, action.item]
                }
            }
        case LOADING:
            return {
                ...state,
                isFetching: true
            }
        case ADD_EMAIL:
            return {
                ...state,
                emails: [...state.emails, action.email]
            }
        case REMOVE_EMAIL:
            return {
                ...state,
                emails: state.emails.filter((el) => el !== action.email),
                isFetching: false
            }
        case ADD_NOTES:
            return {
                ...state,
                notes: action.notes,
                isFetching: false
            }
        case REMOVE_FROM_CART:
            return {
                ...state,
                selections: state.selections.filter(el => el !== action.item.id),
                list: state.list.filter(el => el.id !== action.item.id),
                isFetching: false
            }
        case EMPTY_CART:
            return initialState
        case FAILED:
            return {
                ...state,
                isFetching: false
            }
        default:
            return state
    }
}

export default cartReducer