const END_POINT = "https://admin.devo-catalog.com/api/"
const INDEXED_V = 1

const DEMO_MODE = false

const palette = {
  "primary": { "main": "#e3682a" },
  "secondary": { "main": "#fff" }
}

const homePageData = {
  "title":"Home",
  "data":[{
      "name":"Signature",
      "id": 1,
      "uri":"/collections",
      "params":"signature",
      "img": "img_signature",
      "flex":"flex-end"
    },
    {
      "name":"Essential",
      "id": 2,
      "uri":"/collections",
      "params":"essential",
      "img": "img_essential",
      "flex":"flex-start"
    },
    {
      "name":"Work Space",
      "id": 3,
      "uri":"/collections",
      "params":"workspace",
      "img": "img_workspace",
      "flex":"flex-start"
    },
    {
      "name":"Outdoor",
      "id": 4,
      "uri":"/collections",
      "params":"outdoor",
      "img": "img_outdoor",
      "flex":"flex-start"
    },
    {
      "name":"Game Tables",
      "id": 6,
      "uri":"/collections",
      "params":"game-tables",
      "img": "img_game_tables",
      "flex":"flex-start"
    },
    {
      "name":"Projects",
      "uri":"/projects",
      "params":"projects",
      "img": "img_project",
      "flex":"flex-start"
    }
  ]
}

const salesMenu = {
    "top_left_menu":[
      {"name":"Home","url":"/"},
      {"name":"Collections","url":"/collections"},
      {"name":"Projects","url":"/projects"},
      {"name":"About us","url":"/about-us"}],
    "top_right_menu":[
      {"name":"Selection","url":"/selection"},
      {"name":"Disconnect","url":"/disconnect"}
    ]
  }

const clientMenu = {
    "top_left_menu":[
      {"name":"Collections","url":"/collections"},
      {"name":"About us","url":"/about-us"}],
    "top_right_menu":[
      {"name":"Disconnect","url":"/disconnect"}
    ]
  }

export {
    END_POINT,
    INDEXED_V,
    DEMO_MODE,
    palette,
    homePageData,
    salesMenu,
    clientMenu
}
