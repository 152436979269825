import { combineReducers } from 'redux'
import collections from '../app/collection/collectionsReducer'
import notifications from './notification/notificationReducer'
import layout from '../app/layout/layoutReducer'
import library from './library/libraryReducer'
import filter from '../app/filter/filterReducer'
import auth from '../app/auth/authReducer'
import cart from '../app/cart/cartReducer'



const appReducer = combineReducers({
    library,
    collections,
    notifications,
    filter,
    layout,
    auth,
    cart
})

export default (state, action) => {
    // let newState = state
    if (action.type === 'LOGOUT_AUTH') {
        state = undefined
    }

    return appReducer(state, action)
}
