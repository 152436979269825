//src/app/cart/Popover.js
import React from 'react'
import { Link } from 'react-router-dom'
import { Typography, makeStyles, Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import Card from './card'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'


const Popover = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <Typography variant="body1">Added to cart</Typography>
                <Typography variant="body1" style={{ textDecoration: "underline", cursor: "pointer" }} onClick={props.onClose}>Close</Typography>
            </div>
            <div className={classes.list}>
                {
                    props.selectionList.map((item, index) => {
                        return <Card
                            key={index}
                            item={item}
                            token={props.token}
                            onRemoveFromCart={props.onRemoveFromCart}
                            onOpenPopover={props.onOpenPopover}
                        />
                    })
                }
            </div>
            <div className={classes.footer}>
                <Button variant="contained" fullWidth color="primary" onClick={() => props.onClose()} component={Link} to="/cart" className="btn-default btn-primary">View List&nbsp;<ArrowRightIcon /></Button>&nbsp;
                <Button variant="contained" fullWidth color="primary" onClick={() => props.onClose()} className="btn-default btn-secondary">Close</Button>
            </div>
        </div>
    )
}

Popover.propTypes = {
    onClose: PropTypes.func.isRequired
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: 24,
        minHeight: 150,
        minWidth: 400,
        position: "relative"
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 24
    },
    list: {
        overflowY: "auto",
        maxHeight: 200,
        // height: 200
    },
    footer: {
        marginTop: 24,
        display: "flex",
        justifyContent: "space-between"
    }
}))

export default Popover
