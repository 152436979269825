//src/app/layout/actions.js
import axios from 'axios'
import { END_POINT } from '../../constant'
import Collections from '../collection/actions'
import { setNotification } from '../../redux/notification/actions'

const OPEN_FILTER = 'OPEN_FILTER'
const GET_FILTERS = 'GET_FILTERS'
const GET_FILTER_STATE = 'GET_FILTER_STATE'
const RESET_SELECTION = 'RESET_SELECTION'


const openFilter = (bool = false) => {
    return {
        type: OPEN_FILTER,
        bool
    }
}


const getFilters = () => {
    return async (dispatch, getState) => {
        try {
            const token = getState().auth.token
            const request = await axios.get(`${END_POINT}filters?token=${token}`);
            const data = request.data

            dispatch(setFilters(data))
        } catch (err) {
            dispatch(setNotification("Server error, please try again", "error"));
        }
    }
}

const setFilters = (data) => {
    return {
        type: GET_FILTERS,
        payload: data
    }
}


const getFilterState = (fieldName, value) => {
    return {
        type: GET_FILTER_STATE,
        fieldName,
        value
    }
}

const filterArray = (array, filters) => {
    const filterKeys = Object.keys(filters);
    return array.filter(item => {
        // validates all filter criteria
        return filterKeys.every(key => {
            // If its an array of object
            if (key === "textures" || key === "finishing" || key === "materials") {
                for (let i = 0; i < item[key].length; i++) {
                    if (filters[key] === item[key][i].id) {
                        return item
                    }
                }
            } else if (key === "category_id") {
                return searchByCategory(filters, key, item)
            } else if (key === "country_sold_id" || key === "country_available_id") {
                return searchByCountries(filters, key, item)
            } else if (key === "search") {
                if(item.title.toLowerCase().indexOf(filters[key]) !== -1){ return item; }
            } else if (key === "type") {
              if (item.type_id === filters[key]) {
                  return item;
              }
            } else if (key === "year_id") {
                if (item.year === filters[key]) {
                    return item;
                }
            } else {
                if (filters[key] === item[key]) return item
            }
            return null
        })
    })
}

const searchByCountries = (filters, key, item) => {
    let name = key === 'country_sold_id' ? "countries_sold" : "countries_available"
    if (item[name].length > 0) {
        const byCountry = (filters, key, item) => {
            let result = null;
            for (let i = 0; i < item[name].length; i++) {
                if (filters[key] === item[name][i].id) {
                    result = item
                }
            }
            return result
        }
        return byCountry(filters, key, item)
    }
}

const searchByCategory = (filters, key, item) => {
    if (item.products.length > 0) {
        const byCategory = (filters, key, item) => {
            let result = null;
            for (let i = 0; i < item.products.length; i++) {
                if (filters[key] === item.products[i].category_id) {
                    result = item
                }
            }
            return result
        }
        return byCategory(filters, key, item)
    }
}

const applyFilter = () => {
    // do stuff here and update
    return (dispatch, getState) => {
        const collections = getState().collections.unFilteredList
        const filterKeys = getState().filter.selected;
        dispatch(openFilter(false))

        if (Object.keys(filterKeys).length === 0) {
            dispatch(setNotification("No filter has been selected", "error"));
        } else {
            const newCollections = filterArray(collections, filterKeys);
            dispatch(Collections.receiveCollections(newCollections));

            console.log(newCollections)

            if (!newCollections || newCollections.length === 0) {
                dispatch(setNotification("No collection found", "error"));
                dispatch(openFilter(false))
            } else {
                // close filter-box
                dispatch(openFilter(false))
            }
        }
    }
}


const sortBy = (direction) => {

    return (dispatch, getState) => {
        const collections = getState().collections.list
        console.log("COL", collections)

        let newCollections = []
        switch (direction) {
            case "ASC":
                newCollections = collections.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                break;
            case "DESC":
                newCollections = collections.sort((a, b) => (a.name < b.name) ? 1 : ((b.name < a.name) ? -1 : 0));
                break;
            case "PRICE_ASC":
                newCollections = collections.sort((a, b) => (a.price < b.price) ? 1 : ((b.price < a.price) ? -1 : 0));
                break;
            case "PRICE_DESC":
                newCollections = collections.sort((a, b) => (a.price < b.price) ? 1 : ((b.price < a.price) ? -1 : 0));
                break;

            default:
                break;
        }

        dispatch(Collections.receiveCollections(newCollections));
    }
}



const resetResult = () => {
    return (dispatch, getState) => {
        const collections = getState().collections.unFilteredList
        dispatch(resetSelection())
        dispatch(Collections.receiveCollections(collections))

    }
}

const resetSelection = () => {
    return {
        type: RESET_SELECTION,
    }
}


export {
    openFilter,
    getFilters,
    getFilterState,
    applyFilter,
    resetResult,
    sortBy,

    OPEN_FILTER,
    GET_FILTERS,
    GET_FILTER_STATE,
    RESET_SELECTION
}
