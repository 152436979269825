//src/app/pages/home.js
import React from 'react'
import { connect } from 'react-redux'
import { Link } from "react-router-dom"
import { Typography, Grid, withStyles } from '@material-ui/core'
import { setNotification } from '../../redux/notification/actions'
import { setDownloaded } from '../layout/actions'
import Video from '../common/video'
import { homePageData } from '../../constant';

import Collections from '../collection/actions';

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    container: {
        overflow: "hidden",
        width: "100%",
        textAlign: "center"

    },
    name: {
        fontSize: 20,
        marginTop: 10,
        textAlign: "left !important"
    },
    img: {
        maxWidth: "100%",
        maxHeight: "100%",
        width: "100%"
    }
})



class Home extends React.Component {

    componentDidMount() {
        this.props.onGetCollections();
    }

    render() {

        const { staticData, staticContent, token, classes, online } = this.props

        return <div className={classes.root} style={{ marginTop: this.props.staticData && this.props.staticData.video ? 104 : 50 }}>
            {
                this.props.staticData && this.props.staticData.video ?
                    <Video video={this.props.staticData.video} videoOgv={this.props.staticData.video} token={token} />
                    : online ? <div><br /> <br /> <br /></div> : null
            }

            <div style={{ margin: 48 }}>

                <Grid container spacing={5} justify="center">

                    {staticData.data && staticData.data.length > 0 && staticData.data.map((item, key) =>

                      <Grid item xs={12} sm={6} md={6} style={{ padding: 20 }}>
                          <Link
                            to={{
                              pathname: item.uri,
                              state: item.id
                            }}
                            className={classes.container}>
                              <img src={staticContent[item.img] ? staticContent[item.img].path : null} className={classes.img} alt={item.name} />
                          </Link>
                          <Typography variant="caption" align="left" className={classes.name}>{item.name}</Typography>
                      </Grid>

                    )}


                </Grid>
            </div>


        </div>
    }
}

const mapStateToProps = state => {

    return {
        staticData: homePageData,
        staticContent: state.library.company_theme.payload.home_page,
        token: state.auth.token,
        user: state.auth.user,
        online: state.layout.online,
        downloaded: state.layout.downloaded
    }
}

const mapDispatchToProps = dispatch => {
    const onGetCollections = () => dispatch(Collections.getCollections());

    return {
        onGetCollections,
        setNotification,
        setDownloaded
    }
}

const StyledHome = withStyles(styles)(Home)

export default connect(mapStateToProps, mapDispatchToProps)(StyledHome);
