//src/app/home/CollectionContainer.js
import React from 'react'
import { connect } from 'react-redux'
import Collections from './actions'
import CollectionComponent from './CollectionComponent'
import FilterContainer from '../filter/FilterContainer'
import { getFilterState, applyFilter } from '../filter/actions'
import { addToCart, removeFromCart } from '../cart/actions'
import { Typography } from '@material-ui/core'


class CollectionContainer extends React.Component {

    componentDidMount() {
        if (this.props.location.state) {
            this.filterData(this.props.location.state)
        }
    }

    filterData = (filter) => {
        this.props.onGetFilterState("type", filter)
        this.props.onApplyFilter()
    }

    render() {
        const { collections, onViewCollection, page, onAddToCart, selections, onRemoveFromCart, displayFilter, token, isClient } = this.props

        if (this.props.isFetching) {
            return <p style={{ marginTop: "10%", textAlign: "center" }}>Loading....</p>
        }

        if (!collections) {
            return <Typography variant="body1" align="center">No collections found !</Typography>
        }

        const data = page === "project" ? collections.filter((el) => { return el.is_project }) : collections.filter((el) => { return !el.is_project })

        return (
            <div id="collections">
                {displayFilter ? <FilterContainer totalCollections={data && data.length} /> : false}
                <CollectionComponent
                    collections={data}
                    token={token}
                    onViewCollection={onViewCollection}
                    onAddToCart={onAddToCart}
                    onRemoveFromCart={onRemoveFromCart}
                    showCartBtn={page !== "project"}
                    selections={selections}
                    isClient={isClient} />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        collections: state.collections.list,
        isFetching: state.collections.isFetching,
        selections: state.cart.selections,
        token: state.auth.token,
        isClient: state.auth.user.is_client
    }
}

const mapDispatchToProps = dispatch => {
    const onViewCollection = (id) => dispatch(Collections.viewCollection(id));
    const onGetCollections = () => dispatch(Collections.getCollections());
    const onGetFilterState = (fieldName, value) => dispatch(getFilterState(fieldName, value))
    const onApplyFilter = () => dispatch(applyFilter())
    const onAddToCart = (item) => dispatch(addToCart(item))
    const onRemoveFromCart = (item) => dispatch(removeFromCart(item))

    return {
        onViewCollection,
        onGetCollections,
        onGetFilterState,
        onApplyFilter,
        onAddToCart,
        onRemoveFromCart
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectionContainer)
