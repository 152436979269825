//src/app/common/thumbnail.js
import React from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { makeStyles, Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
// import CardMedia from '@material-ui/core/CardMedia';
import { red } from '@material-ui/core/colors';
import { Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import Img from './img'

const useStyles = makeStyles(theme => ({
    card: {
        maxWidth: "100%",
        boxShadow: "none",
        borderRadius: 0
    },
    add: {
        marginLeft: 'auto',
    },
    avatar: {
        backgroundColor: red[500],
    },
}))



const Thumbnail = ({ item, clickAction, showCartBtn, isSelected, onRemoveFromCart, token, isClient }) => {
    const classes = useStyles();
    // console.log("POP", item)

    var thumbPath;

    if(item.thumb){
      thumbPath = item.thumb;
    } else {
      thumbPath = item.images && item.images[0] ? item.images[0].path : null;
    }


    return (

        <Card className={classes.card} data-anchor="test">

            <Link to={`/collections/${item.id}`}
                style={{
                    textDecoration: "none",
                    width: "100%",
                }}>

                {thumbPath && <Img image={thumbPath} />}

            </Link>

            <div style={{ display: "flex", justifyContent: "space-between", height: 50, alignItems: 'center' }}>
                <Typography variant="body1">{item.name}</Typography>
                {!isClient && showCartBtn ? <Button
                    onClick={() => {
                        if (isSelected) {
                            onRemoveFromCart(item)
                        } else {
                            clickAction(item)
                        }
                    }}
                    size="small"
                    style={{
                        backgroundColor: isSelected ? "black" : "white",
                        color: isSelected ? "white" : "black",
                        borderRadius: 27,
                        fontWeight: 600,
                        borderColor: "black"
                    }}
                    variant="outlined">
                    {
                        !isClient && isSelected ? <RemoveIcon style={{ fontSize: 14, fontWeight: 600 }} />
                            : <AddIcon style={{ fontSize: 14, fontWeight: 600 }} />
                    }
                    {isSelected ? "Remove from cart" : "Add to cart"}

                </Button> : null}
            </div>
        </Card>

    );
}

export default Thumbnail;

Thumbnail.propTypes = {
    item: PropTypes.object.isRequired
}
