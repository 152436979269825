//src/app/pages/downloads.js

import React from 'react';
import { connect } from 'react-redux'
import { Grid, Typography } from '@material-ui/core'
import ContentLayout from '../layout/contentLayout'
import GetAppIcon from '@material-ui/icons/GetApp'


const sections = (unFilteredList) => {
    let array = []
    let collections = []
    array = unFilteredList.map((item) => {
        return item.name[0];
    }).filter((value, index, self) => {
        return self.indexOf(value) === index;
    })

    for (let i = 0; i < array.sort().length; i++) {
        collections = [...collections, { group: array[i], collections: unFilteredList.filter((x) => { return x.name.substring(0, 1) === array[i] }) }]
    }
    return collections
}


const Downloads = (props) => {
    const { unFilteredList, staticContent } = props

    return <ContentLayout img={staticContent.img_header.path} text={staticContent.title} showHeader={false}>
        <div style={{ margin: 48 }}>
            <Typography style={{ padding: 20, backgroundColor: "#f9f9f9", borderRadius: 4 }} variant="h3" align="center">{props.staticContent.title}</Typography>
            <div style={{ marginTop: 48 }}>

                {
                    sections(unFilteredList).map((row, index) => {
                        return <div key={index} style={{ marginBottom: 84 }}>
                            <Typography variant="body1" style={{ marginBottom: 24 }}>{row.group} -</Typography>
                            <Grid container spacing={5}>
                                {
                                    row.collections.map((col, index) => {
                                        return <Grid item xs={12} sm={3} md={3} key={index}>
                                            <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                                <div style={{ marginRight: 15 }}>
                                                    <img src={col.images[0] ? col.images[0].path + `?width=130` : ""} alt={col.name} height="80" style={{ maxWidth: 130, minWidth: 130, width: "auto" }} />
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                                    <Typography variant="body2">{col.name}</Typography>
                                                    <a href={col.storage_link} rel="noopener noreferrer" target="_blank" className="btn-download"><GetAppIcon className="icon-download" />&nbsp;Download</a>
                                                </div>
                                            </div>

                                        </Grid>
                                    })
                                }
                            </Grid>
                        </div>

                    })
                }
            </div>

        </div>

    </ContentLayout >
}

const mapStateToProps = state => {
    return {
        unFilteredList: state.collections.unFilteredList,
        token: state.auth.token,
        staticContent: state.library.company_theme.payload.downloads_page
    }
}


export default connect(mapStateToProps)(Downloads);
