//src/app/pages/about.js
import React from 'react'
import { connect } from 'react-redux'
import { Typography, Grid, Divider, Hidden, makeStyles } from '@material-ui/core'
import Video from '../common/video'
import ContentLayout from '../layout/contentLayout'
import WorldMap from '../../assets/world_map.svg'


const useStyles = makeStyles(theme => ({
    mapWrapper: {
        backgroundColor: "#f9f9f9",
        padding: 54,
        [theme.breakpoints.down("sm")]: {
            padding: 12
        }
    }
}))
const About = (props) => {
    const classes = useStyles()

    return <ContentLayout img={props.about.img_header.path} text={props.about.title} showHeader={true}>
        <div>
            <Grid container justify="space-between" spacing={0} style={{ padding: "5%" }}>
                <Grid item xs={12} sm={6} md={6}>

                    <Typography variant="h3" align="left" style={{ marginBottom: 24 }}>{props.about.subtitle}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="body2" style={{ color: "#919191" }}>{props.about.paragraphe}</Typography>
                </Grid>
            </Grid>
            <div className={classes.mapWrapper}>
                <Grid container spacing={0} >
                    <Hidden only={["sm", "md", "lg"]}>
                        <Grid item xs={12} sm={6} md={6}>
                            <img style={{ maxWidth: "100%" }} src={WorldMap} alt="world-map" height="337" />
                        </Grid>
                    </Hidden>
                    {props.about.containers_total &&
                      <Grid item xs={12} sm={6} md={6} style={{ alignItems: "center", display: "flex" }}>
                        <Grid container justify="space-between" spacing={5} >
                            <Grid item xs={12} sm={6} md={6}>
                                <Typography variant="h6" style={{ whiteSpace: "nowrap" }}>{props.about.containers_subtitle}</Typography>
                                <Typography variant="h3">{props.about.containers_total}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <Typography variant="h6" style={{ whiteSpace: "nowrap" }}>{props.about.clients_subtitle}</Typography>
                                <Typography variant="h3">{props.about.clients_total}</Typography>
                            </Grid>
                            <Hidden only={["xs"]}>
                                <Grid item xs={12}>
                                    <Divider style={{ maxWidth: 337 }} />
                                </Grid>
                            </Hidden>

                            <Grid item xs={12}>
                                <Typography variant="h6" style={{ whiteSpace: "nowrap" }}>{props.about.countries_subtitle}</Typography>
                                <Typography variant="h3">{props.about.countries_total}</Typography>
                            </Grid>
                        </Grid>
                      </Grid>
                    }

                    { props.about.list_item_1 &&
                      <Grid item xs={12} sm={6} md={6} style={{ alignItems: "center", display: "flex" }}>
                        <Grid container justify="space-between" spacing={5} >
                            <ul style={{listStyle: "none"}}>
                              {props.about.list_item_1 && <li style={{ paddingBottom: '20px'}}><Typography variant="h6">{props.about.list_item_1}</Typography></li> }
                              {props.about.list_item_2 && <li style={{ paddingBottom: '20px'}}><Typography variant="h6">{props.about.list_item_2}</Typography></li> }
                              {props.about.list_item_3 && <li style={{ paddingBottom: '20px'}}><Typography variant="h6">{props.about.list_item_3}</Typography></li> }
                              {props.about.list_item_4 && <li style={{ paddingBottom: '20px'}}><Typography variant="h6">{props.about.list_item_4}</Typography></li> }
                            </ul>
                        </Grid>
                      </Grid>
                    }

                    <Hidden only={["xs"]}>
                        <Grid item xs={12} sm={6} md={6}>
                            <img style={{ maxWidth: "100%" }} src={WorldMap} alt="world-map" height="337" />
                        </Grid>
                    </Hidden>

                </Grid>
            </div>


            {
                props.about.link_video ?
                    <div style={{ marginTop: 48 }}>
                        <Video video={props.about.link_video} />
                    </div>
                    : <div><br /> <br /> <br /></div>
            }

        </div >
    </ContentLayout >
}

const mapStateToProps = state => {
    return {
        about: state.library.company_theme.payload.about_us_page,
        isMobile: true,
        token: state.auth.token
    }
}


export default connect(mapStateToProps)(About);
