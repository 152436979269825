//src/app/pages/panier.js
import React from 'react'
import { connect } from 'react-redux'
import { Typography, Grid, makeStyles, Paper } from '@material-ui/core'


const img = ""


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: 48
    },
    content: {
        padding: 12,
        minHeight: 230,
        width: 240
    },
    imageWrapper: {
        backgroundColor: "black",
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        height: 240,
        width: 240,
        marginBottom: 10
    },
    title: {
        fontSize: 16,
        marginTop: 10
    }
}))

const Product = (props) => {
    const classes = useStyles();

    return <div style={{ marginTop: 64 }}>
        <div style={{ width: "100%", backgroundImage: `url(${img})`, backgroundSize: "cover", paddingTop: 150, paddingBottom: 150 }}>
            <Typography style={{ backgroundColor: "rgba(255,255,255,.4)" }} variant="h1" align="center">Product page</Typography>
        </div>
        <div className={classes.root}>
            <Grid container justify="center" spacing={10}>
                {
                    props.products.map((x, index) => {
                        return <Grid key={index} item xs={12} sm={4} md={3}>
                            <Paper className={classes.content}>
                                <div className={classes.imageWrapper}>
                                    <img src={x.images[0].path} alt={x.images[0].title} width="100%" />
                                </div>

                                <Typography variant="p" className={classes.title}>{x.name}</Typography>
                            </Paper>

                        </Grid>
                    })
                }

            </Grid>
        </div>

    </div>
}

const mapStateToProps = state => {
    return {
        products: state.collections.products
    }
}


export default connect(mapStateToProps)(Product);
