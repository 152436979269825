//src/app/pages/home.js
import React from 'react'
import { withStyles } from '@material-ui/core'


const styles = theme => ({
    media: {
        height: 'auto',
        width: '100%',
        margin: 'auto'
    },
    thumb: {
        height: 'auto',
        width: '80px',
        margin: '5px',
    }
})


class Img extends React.Component {

    state = {
        blob: null,
        image: this.props.image
    }

    componentDidMount() {
    //    this.getBlob(this.props.image)
    }

    componentDidUpdate() {
    //    if (this.state.image !== this.props.image) {
    //        this.getBlob(this.props.image)
    //        this.setState({ image: this.props.image })
    //    }

    }

    // getBlob = (image) => {
    //     const request = indexedDB.open('catalogue-database', 1);
    //     const imageId = image.substring(
    //         image.lastIndexOf("/") + 1,
    //         image.lastIndexOf(".jpg")
    //     )
    //     let blob = null
    //
    //     request.onsuccess = event => {
    //         const db = event.target.result;
    //
    //         const transaction = db.transaction(['images']);
    //         const objectStore = transaction.objectStore('images');
    //         const response = objectStore.get(imageId);
    //
    //         response.onerror = event => {
    //             console.log('error');
    //         };
    //
    //         response.onsuccess = event => {
    //             if (response.result) {
    //                 blob = response.result.blob
    //                 this.setState({ blob: window.URL.createObjectURL(blob) })
    //             } else {
    //                 this.setState({ blob: image })
    //             }
    //         }
    //     }
    // }

    render() {
        const { classes } = this.props;

        if(this.props.thumb){
          //return <img src={this.state.blob} className={classes.thumb} alt={Date.now()} />
          return <img src={this.props.image} className={classes.thumb} alt={Date.now()} />

        } else {
          //return <img src={this.state.blob} className={classes.media} alt={Date.now()} />
          return <img src={this.props.image} className={classes.media} alt={Date.now()} />
        }

    }

}

export default withStyles(styles)(Img);
