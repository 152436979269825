//src/app/layout/layoutReducer.js

import {
    OPEN_FILTER,
    GET_FILTERS,
    GET_FILTER_STATE,
    RESET_SELECTION
} from './actions'


const initialState = {
    open: false,
    filters: null,
    selected: {}
}


const filterReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_FILTER:
            return {
                ...state,
                open: action.bool
            }
        case GET_FILTERS:
            return {
                ...state,
                filters: action.payload
            }
        case RESET_SELECTION:
            return {
                ...state,
                selected: {}
            }
        case GET_FILTER_STATE:
            return {
                ...state,
                selected: { ...state.selected, [action.fieldName]: action.value }
            }
        default:
            return state;
    }
}

export default filterReducer;