//src/app/collection/collectionsReducer.js

import {
    REQUEST_COLLECTIONS,
    RECEIVE_COLLECTIONS,
    FAILED_COLLECTIONS,
    GET_COLLECTION,
    SAVED_INITIAL_LIST,
    RECEIVE_PRODUCTS
} from './actions'

const initialState = {
    isFetching: false,
    isError: false,
    list: [],
    project: [],
    unFilteredList: [],
    products: [],
    item: null
}

const collectionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_COLLECTIONS:
            return {
                ...state,
                isFetching: true,
                isError: false
            }
        case RECEIVE_COLLECTIONS:
            return {
                ...state,
                isFetching: false,
                isError: false,
                list: action.collections
            }
        case RECEIVE_PRODUCTS:
            return {
                ...state,
                products: action.products
            }
        case SAVED_INITIAL_LIST:
            return {
                ...state,
                unFilteredList: action.collections
            }
        case GET_COLLECTION:

            return {
                ...state,
                isFetching: false,
                isError: false,
                item: action.payload
            }
        case FAILED_COLLECTIONS:
            return {
                ...state,
                isFetching: false,
                isError: true,
                list: action.collections
            }
        default:
            return state
    }
}

export default collectionsReducer