//src/app/pages/home.js
import React from 'react'
import { connect } from 'react-redux'
import CollectionContainer from '../collection/CollectionContainer'
import ContentLayout from '../layout/contentLayout'



const Project = (props) => {

    return <ContentLayout img={props.static.img_header.path} text={props.static.title} showHeader={true}>
        <div>
            <CollectionContainer {...props} page="project" displayFilter={true} />
        </div>

    </ContentLayout>
}

const mapStateToProps = state => {
    return {
        static: state.library.company_theme.payload.projects_page
    }
}


export default connect(mapStateToProps)(Project);
