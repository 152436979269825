//src/app/home/actions.js
import axios from 'axios'
import { END_POINT } from '../../constant'
import { setNotification } from '../../redux/notification/actions'

export const REQUEST_COLLECTIONS = 'REQUEST_COLLECTIONS'
export const RECEIVE_COLLECTIONS = 'RECEIVE_COLLECTIONS'
export const GET_COLLECTION = 'GET_COLLECTION'
export const FAILED_COLLECTIONS = 'FAILED_COLLECTIONS'
export const SAVED_INITIAL_LIST = 'SAVED_INITIAL_LIST'
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS'

const requestcollections = () => {
    return {
        type: REQUEST_COLLECTIONS,
    }
}
/**
 * @params Array collections
 * @return Array products
 */
// const getProducts = (collections) => {
//     return collections.filter((el) => {
//         return el.products.filter((prod) => {
//             return prod
//         })
//     })
// }


const getCollections = () => {

    return async (dispatch, getState) => {
        // Loading start
        dispatch(requestcollections())

        try {
            const token = getState().auth.token
            const request = await axios.get(`${END_POINT}collections?token=${token}`);
            const data = request.data;

            dispatch(receiveCollections(data))
            dispatch(saveCollections(data))
        } catch (err) {
            dispatch(setNotification(err.error, "error"))
            dispatch(failedCollections())
        }
    }
}

const viewCollection = (id) => {

    return async (dispatch, getState) => {


        try {
            const collections = getState().collections.unFilteredList
            const data = await collections.filter((el) => { return el.id === Number(id) })

            if (data.length === 0) {
                throw new Error()
            }

            dispatch(getCollection(data[0]))
        } catch (err) {

            dispatch(setNotification(err.error, "error"))
            dispatch(failedCollections())
        }
    }
}

const getCollection = (item) => ({
    type: GET_COLLECTION,
    payload: item
})


const receiveCollections = collections => ({
    type: RECEIVE_COLLECTIONS,
    collections
})

// const receiveProducts = products => ({
//     type: RECEIVE_PRODUCTS,
//     products
// })

const saveCollections = collections => ({
    type: SAVED_INITIAL_LIST,
    collections
})

const failedCollections = () => ({
    type: FAILED_COLLECTIONS,
})


export default {
    viewCollection,
    getCollections,
    getCollection,
    receiveCollections,
    saveCollections
}