import React from 'react';
import { NavLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing.unit * 1,
        ...theme.mixins.toolbar,
        justifyContent: 'center',
    }
}));

const DrawerLeft = (props) => {
    const classes = useStyles();
    const { open, onHandleDrawerOpen, theme, isClient, menu } = props

    return (
        <div className={classes.root}>
            <Drawer
                className={classes.drawer}
                variant="temporary"
                anchor="left"
                open={open}
                onClose={onHandleDrawerOpen}
                onClick={() => onHandleDrawerOpen()}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <img src={theme.common.logo.path } alt="catalogue-logo" width="auto" height="64" />
                </div>
                <Divider />
                <List>
                    {
                        menu.top_left_menu.map((menu, index) => {
                            return <ListItem button key={index} component={NavLink} to={menu.url} >
                                <ListItemText primary={menu.name} />
                            </ListItem>
                        })
                    }
                </List>
                <Divider />
                <List>
                    {
                        !isClient ?
                            <ListItem component={NavLink} to="/cart">
                                <ListItemIcon><ShoppingCartIcon /></ListItemIcon>
                                <ListItemText primary="Cart" />
                            </ListItem>
                            : null
                    }

                    <ListItem button onClick={props.onLogout} >
                        <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                        <ListItemText primary="Disconnect" />
                    </ListItem>


                </List>
            </Drawer>
        </div >
    );
}


export default DrawerLeft;
