//src/app/auth/actions.js
import axios from 'axios'
import { END_POINT } from '../../constant'
// import { setNotification } from '../../redux/notification/actions'
import { history } from '../../routes/history'
import Collections from '../collection/actions'
import { getFilters } from '../filter/actions'
import { getData } from "../../redux/library/actions"

import { unregister } from '../../serviceWorker';


export const REQUEST_AUTH = 'REQUEST_AUTH'
export const RECEIVE_AUTH = 'RECEIVE_AUTH'
export const FAILED_AUTH = 'FAILED_AUTH'
export const STATE_AUTH = 'STATE_AUTH'
export const LOGOUT_AUTH = 'LOGOUT_AUTH'

const requestAuth = () => {
    return {
        type: REQUEST_AUTH,
    }
}

const getAuthenticated = () => {


    return async (dispatch, getState) => {
        // Loading start
        dispatch(requestAuth());

        const data = getState().auth.item
        let bodyFormData = new FormData();
        bodyFormData.set('login', data.email);
        bodyFormData.set('password', data.password);

        axios.post(`${END_POINT}auth/login`,
            bodyFormData,
            {
                mode: 'cors',
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(function (response) {
                return response.data
            })
            .then(res => {


                const token = res.token;
                const user = res.user;
                dispatch(receiveAuth(user, token))
                dispatch(getData("COMPANY_THEME"))
                dispatch(getFilters())
                dispatch(Collections.getCollections())

                // Redirect to home page
                if (user.is_client) {
                    history.push("/collections")
                } else {
                    history.push("/")
                }


            }).catch(err => {
                dispatch(failedAuth())
                history.push("/login")
            })
    }

}

const requestLogout = () => {

    return dispatch => {
        // Loading start
        dispatch(setLogout())

        sessionStorage.clear()
        localStorage.clear()

        unregister(); // update service worker

    }
}

const setLogout = () => ({
    type: LOGOUT_AUTH,
})



const onChangeCredential = (fieldName, value) => ({
    type: STATE_AUTH,
    payload: { fieldName, value }
})



const receiveAuth = (user, token) => ({
    type: RECEIVE_AUTH,
    user,
    token
})

const failedAuth = () => ({
    type: FAILED_AUTH
})


export {
    getAuthenticated,
    onChangeCredential,
    requestLogout
}
