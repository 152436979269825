//src/app/pages/panier.js
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { history } from '../../routes/history'
import { IconButton, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import AccessoryContainer from '../accessory/AccessoryContainer'
import ContentLayout from '../layout/contentLayout'


const Accessory = (props) => {
    const isClient = props.user && props.user.is_client

    return <ContentLayout showHeader={false}>
        <div style={{ marginTop: 20 }}>
            <IconButton variant="outline" onClick={(n) => { isClient ? history.push("/collections") : history.go(-1) }} ><ArrowBackIcon /></IconButton>
            <Typography component="span">
              <Link to="/collections" style={{ textDecoration: "none" }}>Collections </Link>
              / <span style={{ fontWeight: 600 }}>{props.item.name}</span>
            </Typography>
        </div>

        <AccessoryContainer {...props} />
    </ContentLayout>
}

const mapStateToProps = state => {

    return {
        item: state.collections.item || {},
        user: state.auth.user
    }
}

export default connect(mapStateToProps)(Accessory);
