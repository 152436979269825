import axios from "axios";
import { END_POINT, INDEXED_V } from '../constant'

/**
 * Save data in queue
 * @param {*} data // object of cart
 *
 */
const saveData = (data) => {
    const request = window.indexedDB.open("catalogue-database", INDEXED_V)

    request.onupgradeneeded = (evt) => {
        var db = evt.target.result;

        if (!db.objectStoreNames.contains("queued")) {
            // Create an objectStore for this database
            db.createObjectStore("queued", { keyPath: "name", autoIncrement: true });
        }
    }

    request.onerror = (evt) => {
        console.log("Error", evt.target)
    }

    request.onsuccess = (evt) => {
        let db = evt.target.result;
        if (request.readyState === "done") {
            let transaction = db.transaction(["queued"], "readwrite");
            let store = transaction.objectStore("queued")

            // define data
            let toStore = {
                name: Date.now(),
                data: data
            }
            store.add(toStore)
        }
    }
}

/**
 * Send all data when back online
 */
const sendAll = () => {

    const request = indexedDB.open('catalogue-database', INDEXED_V);
    request.onsuccess = event => {
        const db = event.target.result;
        let transaction;

        try {
          transaction = db.transaction(["queued"]);

        } catch (e) {
          return null;
        }

        const objectStore = transaction.objectStore("queued");
        const response = objectStore.getAll();


        response.onerror = event => {
            console.log('error');
        }

        response.onsuccess = async event => {
            const result = event.target.result;
            const token = JSON.parse(localStorage.getItem("catalogue-state")).auth.token
            const array = []

            for (let i = 0; i < result.length; i++) {
                try {
                    const formData = new FormData();
                    formData.set("emails", result[i].data.emails)
                    formData.set("notes", result[i].data.notes)
                    formData.set("collections", result[i].data.collections)

                    await axios.post(`${END_POINT}create/selection?token=${token}`, formData)

                    // Make a request to delete the specified record out of the object store
                    array.push(result[i].name)

                } catch (err) {
                    console.log("Something wrong", err)
                }
            }

            _deleteData(array)
        }
    }

}
/**
 * Delete data from indexedDB
 * @param {*} array of data name
 *
 */
const _deleteData = (array) => {
    const request = indexedDB.open('catalogue-database', INDEXED_V);
    request.onsuccess = event => {
        const db = event.target.result;

        const transaction = db.transaction(["queued"], "readwrite");
        const objectStore = transaction.objectStore("queued");
        const response = objectStore.getAll();

        response.onerror = event => {
            console.log('error');
        }
        for (let i = 0; i < array.length; i++) {
            objectStore.delete(array[i])
        }
    }
}


export {
    saveData,
    sendAll
}
