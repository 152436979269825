//src/app/cart/actions.js

import axios from "axios"
import { END_POINT } from '../../constant'
import { setNotification } from '../../redux/notification/actions'
import { saveData } from '../../services/sendQueued'


const ADD_TO_CART = 'ADD_TO_CART'
const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
const EMPTY_CART = 'EMPTY_CART'
const ADD_EMAIL = 'ADD_EMAIL'
const REMOVE_EMAIL = 'REMOVE_EMAIL'
const ADD_NOTES = 'ADD_NOTES'
const LOADING = 'LOADING'
const FAILED = 'FAILED'




const addEmail = email => {
    return {
        type: ADD_EMAIL,
        email
    }
}

const removeEmail = email => {
    return {
        type: REMOVE_EMAIL,
        email
    }
}

const addNotes = notes => {
    return {
        type: ADD_NOTES,
        notes
    }
}

const addToCart = item => {
    return {
        type: ADD_TO_CART,
        item
    }
}

const removeFromCart = item => {
    return {
        type: REMOVE_FROM_CART,
        item
    }
}

const emptyCart = () => {
    return {
        type: EMPTY_CART
    }
}


const sendCart = () => {
    return async (dispatch, getState) => {

        // Loading 
        dispatch(loading())


        const collections = getState().cart.selections
        const emails = getState().cart.emails
        const notes = getState().cart.notes
        const token = getState().auth.token
        const data = {
            emails: emails,
            notes: notes,
            collections: collections
        }


        if (!collections || !emails) {
            dispatch(setNotification("Please make sure you add at least one email & one selection", "error"))
        }

        let bodyFormData = new FormData();
        bodyFormData.set("emails", emails)
        bodyFormData.set("notes", notes)
        bodyFormData.set("collections", collections)

        if (!navigator.onLine) {
            saveData(data)
            dispatch(setNotification("Your export is saved and will be sent later automatically when you get back online.", "warning"))
            dispatch(emptyCart())
            return
        }


        axios.post(`${END_POINT}create/selection?token=${token}`,
            bodyFormData,
            {
                mode: 'cors',
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(response => {
                return response.data
            }).then(res => {
                // Empty cart when finish
                dispatch(emptyCart())

                dispatch(setNotification("Your selections has been sent", "success"))
            }).catch(err => {
                dispatch(failed())
                dispatch(setNotification("Server error, please try again !", "error"))
            })
    }
}

const loading = () => {
    return {
        type: LOADING,
    }
}

const failed = () => {
    return {
        type: FAILED,
    }
}


export {
    addToCart,
    removeFromCart,
    addEmail,
    addNotes,
    removeEmail,
    sendCart,

    EMPTY_CART,
    REMOVE_FROM_CART,
    ADD_TO_CART,
    ADD_EMAIL,
    REMOVE_EMAIL,
    ADD_NOTES,
    LOADING,
    FAILED
}
