//src/app/pages/home.js
import React from 'react'
import { connect } from 'react-redux'
import { Typography, Grid, makeStyles, Divider } from '@material-ui/core'
import ContentLayout from '../layout/contentLayout'


const useStyles = makeStyles(theme => ({
    wrapper: {
        marginBottom: 48
    }
}));


const renderThumbnail = (array, defaultImg, token) => {
    return array.map((x, index) => {
        let img = x.image ? x.image : defaultImg
        return <Grid item xs={6} sm={3} md={2} key={index}>
            <div style={{ height: 140, width: "auto" }} >
                <img src={img} alt={x.code} style={{ height: "100%", width: "100%" }} />
                <Typography variant="body2">{x.name}</Typography>
            </div>
        </Grid>
    })
}

const Finishing = (props) => {
    const classes = useStyles();


    return <ContentLayout img={props.staticContent.img_header.path} text="Game Tables" showHeader={true}>
        <div className={classes.wrapper}>
            <Typography variant="h6" align="center">Game Tables ({props.finishing.length})</Typography>
            <Divider style={{ marginBottom: 24 }} />
            <Grid container spacing={8}>
                {renderThumbnail(props.finishing, props.defaultImg, props.token)}
            </Grid>

        </div>

    </ContentLayout >
}

const mapStateToProps = state => {
    return {
        textures: state.filter.filters.textures,
        materials: state.filter.filters.materials.filter(el => { return el.parent_id }),
        finishing: state.filter.filters.finishing,
        defaultImg: state.library.company_theme.payload.default_img,
        token: state.auth.token,
        staticContent: state.library.company_theme.payload.finishing_page

    }
}


export default connect(mapStateToProps)(Finishing);
