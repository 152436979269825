//src/app/home/AccessoryComponent.js
import React, { useState, useEffect } from 'react'
import {useLocation} from "react-router-dom";
import PropTypes from 'prop-types'
import { makeStyles, Typography, Button, Grid, Fab } from '@material-ui/core'
import Video from '../common/video'
import AddIcon from '@material-ui/icons/Add'
import GetAppIcon from '@material-ui/icons/GetApp'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
//import Modal from '../common/modal'
import RemoveIcon from '@material-ui/icons/Remove'

import Lightbox from "react-image-lightbox";


const useStyles = makeStyles(theme => ({

    paper: {
        height: 140,
        width: 100,
    },
    control: {
        padding: theme.spacing(2),
    },
    margin: {
        marginBottom: theme.spacing(10)
    },
    carousel: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        maxWidth: "100%",
        overflowX: 'scroll',
        transition: 'all .1s ease'
    }
}));




const AccessoryComponent = ({ item, onAddToCart, onRemoveFromCart, isSelected, token, isClient }) => {
    const classes = useStyles();

    const search = useLocation().search;
    //const product = new URLSearchParams(search).get('product');

    const [photoIndex, setPhotoIndex ] = useState(0);
    const [isOpen, setIsOpen ] = useState(false);
    const [isOpenP, setIsOpenP ] = useState(false);


    // useEffect(() => {
    //
    //   if(product){
    //     var element = document.getElementById('product-'+product);
    //     if(element){
    //       console.log('ELEMENT', element);
    //       element.scrollIntoView(true);
    //     }
    //   }
    //
    // }, [product, item]);


    if (!item) {
        return <p>Loading...</p>
    }

    const handleLightbox = (index) => {
        setPhotoIndex(index);
        setIsOpen(true);
    }

    const handleLightboxP = (index) => {
        setPhotoIndex(index);
        setIsOpenP(true);
    }

    const handleOpen = (e, link) => {
        e.preventDefault()
        window.open(link, "_blank")
    }


    const handleScrollRight = (elementId) => {
        let container = document.getElementById(elementId);
        sideScroll(container, 'right', 30, 300, 40);
    }

    const handleScrollLeft = (elementId) => {
        let container = document.getElementById(elementId);
        sideScroll(container, 'left', 30, 300, 40);
    }

    const sideScroll = (element, direction, speed, distance, step) => {
        let scrollAmount = 0;
        let slideTimer = setInterval(function () {
            if (direction === 'left') {
                element.scrollLeft -= step;
            } else {
                element.scrollLeft += step;
            }
            scrollAmount += step;
            if (scrollAmount >= distance) {
                window.clearInterval(slideTimer);
            }
        }, speed);
    }


    return (
        <div>
            <Typography variant="h1" align="center" style={{ marginBottom: 48 }}>{item.name}</Typography>
            <section style={{ textAlign: "center" }} className={classes.margin}>
                {item && (item.is_project === 0) && !isClient ?
                  isSelected ?
                      <Button color="primary" variant="contained" style={{ marginRight: 10 }} className={isSelected ? "btn-radius btn-dark" : "btn-radius"} onClick={() => { onRemoveFromCart(item) }}><RemoveIcon style={{ padding: 2, borderColor: "white", border: '1px solid white', borderRadius: 25, marginRight: 10 }} />Remove from cart</Button>
                      : <Button color="primary" variant="contained" style={{ marginRight: 10 }} className="btn-radius" onClick={() => { onAddToCart(item) }}><AddIcon style={{ padding: 2, borderColor: "white", border: '1px solid white', borderRadius: 25, marginRight: 10 }} />Add to selection</Button>
                  : null
                }
                {item && item.storage_link &&
                  <Button
                    onClick={(e) => handleOpen(e, item.storage_link)}
                    variant="outlined"
                    className="btn-radius btn-secondary">
                      <GetAppIcon
                        style={{
                            borderColor: "white",
                            padding: 2,
                            border: `1px solid #000`,
                            borderRadius: 25,
                            marginRight: 10 }} />
                      Download Pictures
                  </Button>
                }
            </section>
            {
                item.video ?
                    <section className={classes.margin}>
                        <Video video={item.video && item.video.path} videoOgv={item.video_ogv && item.video_ogv.path} token={token} />
                    </section>
                    : null
            }

            <section className={classes.margin}>
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Typography variant="h3">{item.subtitle}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Typography variant="caption" style={{ color: "#919191" }} align="right">{item.description}</Typography>
                    </Grid>
                </Grid>
            </section>
            <section className={classes.margin} style={{ marginRight: -48 }}>
                <Typography variant="h5" style={{ marginBottom: 24 }}>Lifestyle photos</Typography>

                <div className={classes.carousel} id="lifestyle-container">
                    {
                        item.images &&
                        item.images.map((img, index) => {

                            const htmlLoading = index > 3 ? "lazy" : "eager";

                            return <div key={index} id="content">
                                <div style={{ maxHeight: 400, height: 400, minHeight: 400, backgroundColor: "black", marginRight: 20 }}>
                                  <div style={{ display: "flex", justifyContent: "center", alignItems: 'center', position: "relative", maxHeight: "100vh"}}>
                                      <img onClick={() => handleLightbox(index)} loading={htmlLoading} style={{ maxHeight: 400, cursor: "pointer" }} src={img.path} alt={Date.now()} height="100%" />
                                  </div>
                                </div>
                                <Typography variant="h6" style={{ marginTop: 10 }}>{img.title || img.file_name.toLowerCase()}</Typography>
                            </div>
                        })
                    }

                    {isOpen && (
                        <Lightbox
                          mainSrc={item.images[photoIndex].path}
                          nextSrc={item.images[(photoIndex + 1) % item.images.length]}
                          prevSrc={item.images[(photoIndex + item.images.length - 1) % item.images.length]}
                          imageTitle={photoIndex + 1 + "/" + item.images.length}
                          imagePadding={10}
                          imageLoadErrorMessage="Loading..."
                          onCloseRequest={() => setIsOpen(false)}
                          onMovePrevRequest={() => setPhotoIndex((photoIndex + item.images.length - 1) % item.images.length) }
                          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % item.images.length)}
                          />
                    )}

                </div>
                <div style={{ marginTop: 12 }}>
                    <Fab style={{ backgroundColor: "black", marginRight: 10 }} onClick={() => handleScrollLeft('lifestyle-container')} size="small"><ChevronLeftIcon style={{ color: "white" }} /></Fab>
                    <Fab style={{ backgroundColor: "black" }} size="small" onClick={() => handleScrollRight('lifestyle-container')}><ChevronRightIcon style={{ color: "white" }} /></Fab>
                </div>

            </section>


            {item.product_images && item.product_images.length > 0 &&
              <section id="products" className={classes.margin} style={{ marginRight: -48 }}>
                <Typography variant="h5" style={{ marginBottom: 24 }}>Products</Typography>

                <div className={classes.carousel} id="products-container">
                    {
                        item.product_images.map((img, index) => {

                            const htmlLoading = index > 3 ? "lazy" : "eager";

                            return <div key={index} id={"product-"+img.id}>
                                <div style={{ maxHeight: 400, height: 400, minHeight: 400, backgroundColor: "black", marginRight: 20 }}>
                                  <div style={{ display: "flex", justifyContent: "center", alignItems: 'center', position: "relative", maxHeight: "100vh" }}>
                                        <img onClick={() => handleLightboxP(index)} loading={htmlLoading} style={{ maxHeight: 400, cursor: "pointer" }} src={img.path} alt={Date.now()} height="100%" />
                                  </div>
                                </div>
                                <Typography variant="h6" style={{ marginTop: 10 }}>{img.title || img.file_name.toLowerCase()}</Typography>
                            </div>
                        })
                    }

                    {isOpenP && (
                        <Lightbox
                          mainSrc={item.product_images[photoIndex].path}
                          nextSrc={item.product_images[(photoIndex + 1) % item.product_images.length]}
                          prevSrc={item.product_images[(photoIndex + item.product_images.length - 1) % item.product_images.length]}
                          imageTitle={photoIndex + 1 + "/" + item.product_images.length}
                          imagePadding={10}
                          imageLoadErrorMessage="Loading..."
                          onCloseRequest={() => setIsOpenP(false)}
                          onMovePrevRequest={() => setPhotoIndex((photoIndex + item.product_images.length - 1) % item.product_images.length) }
                          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % item.product_images.length)}
                          />
                    )}


                </div>
                <div style={{ marginTop: 12 }}>
                    <Fab style={{ backgroundColor: "black", marginRight: 10 }} onClick={() => handleScrollLeft('products-container')} size="small"><ChevronLeftIcon style={{ color: "white" }} /></Fab>
                    <Fab style={{ backgroundColor: "black" }} size="small" onClick={() => handleScrollRight('products-container')}><ChevronRightIcon style={{ color: "white" }} /></Fab>
                </div>

            </section>
            }


            <section className={classes.margin}>
                <div style={{
                    paddingTop: 150,
                    paddingBottom: 150,
                    backgroundImage: `url(${item.footer_image && item.footer_image.path ? item.footer_image.path + `?height=300` : "https://woodnet.acte.solutions/api/files/get/171"})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    textAlign: "center"
                }}>
                    <Typography variant="h2" align="center" style={{ color: "white", marginBottom: 24 }}>{item.title}</Typography>
                    {!isClient ?
                        isSelected ?
                            <Button color="primary" variant="contained" style={{ marginRight: 10 }} className={isSelected ? "btn-radius btn-dark" : "btn-radius"} onClick={() => { onRemoveFromCart(item) }}><RemoveIcon style={{ padding: 2, borderColor: "white", border: '1px solid white', borderRadius: 25, marginRight: 10 }} />Remove from cart</Button>
                            : <Button color="primary" variant="contained" style={{ marginRight: 10 }} className="btn-radius" onClick={() => { onAddToCart(item) }}><AddIcon style={{ padding: 2, borderColor: "white", border: '1px solid white', borderRadius: 25, marginRight: 10 }} />Add to selection</Button>
                        : null
                    }

                </div>

            </section>

        </div >

    )
}

AccessoryComponent.propTypes = {
    item: PropTypes.object.isRequired
}

export default AccessoryComponent
