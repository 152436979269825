//src/app/pages/home.js
import React from 'react'
import { Link } from "react-router-dom"
import { makeStyles, Typography } from '@material-ui/core'
// import UploadAssets from '../../lib/uploadAssets'
// import UploadVideo from '../../lib/uploadVideo'
import packageJson from '../../../package.json';


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        height: "100%",
        backgroundColor: "#000",
        padding: 50,
    }

}));

const version = packageJson.version;

const Footer = (props) => {
    const classes = useStyles();
    return <div className={classes.root}>
        <div style={{ display: "flex", justifyContent: "space-between", }}>
            <div>
                <Typography variant="caption" style={{ color: "gray", fontSize: 11 }}>{new Date().getFullYear()} - &#169; <a href="https://thirtydirtyfingers.com" target="_blank" rel="noopener noreferrer">ThirtyDirtyFingers</a></Typography><br/><br/>
                <Typography variant="caption" style={{ color: "gray", fontSize: 11 }}>App version: {version}</Typography>
            </div>

            <div>
                <Link to="/downloads" style={{ color: "white", fontSize: 13 }}>Access all collections</Link>
            </div>

        </div>

    </div >
}

export default Footer;
