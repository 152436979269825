//src/app/auth/LoginComponent.js
import React from 'react'
import { TextField, makeStyles, Button, Grid, Hidden, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { DEMO_MODE } from '../../constant'

const RequestDemoButton = () => {

  function handleDemoRequested(){
    window.location = "mailto:contact@private-catalogue.com?subject=Private Catalogue Demo Request";
  }

  if(DEMO_MODE){

    return(
      <Button
        variant="contained"
        className="btn-default btn-secondary"
        fullWidth
        style={{marginTop: 30}}
        onClick={handleDemoRequested}>
        Request a demo
      </Button>
    );

  } else {

    return('');

  }


}

const LoginComponent = (props) => {
    const classes = useStyles();


    return (
        <Grid container spacing={0}>

            <Grid item xs={12} md={6} sm={6}>
                <div className={classes.root} style={{ height: props.height }}>
                    <div className={classes.content}>
                        {props.message ? <Typography variant="h6" align="left" style={{ color: "red", marginBottom: 10 }}>{props.message}</Typography> : null}
                        <form onSubmit={props.onSubmitLogin}>
                            <TextField
                                label="email"
                                name="email"
                                autoComplete="on"
                                className={classes.textField}
                                style={{ minWidth: 300 }}
                                required
                                variant="outlined"
                                onChange={(e) => props.onChangeCredential(e.target.name, e.target.value)}
                                value={props.auth.email || ""}
                            /><br />
                            <TextField
                                label="password"
                                name="password"
                                autoComplete="on"
                                variant="outlined"
                                type="password"
                                className={classes.textField}
                                style={{ minWidth: 300 }}
                                required
                                onChange={(e) => props.onChangeCredential(e.target.name, e.target.value)}
                                value={props.auth.password || ""}
                            />

                            <br />
                            <Button disabled={props.isFetching} type="submit" variant="contained" className="btn-default btn-primary" fullWidth endIcon={<ArrowRightIcon />}>{props.isFetching ? "Loading..." : "Connect"}</Button>

                        </form>

                        <RequestDemoButton />

                    </div>
                </div >
            </Grid>
            <Hidden xsDown>
                <Grid item sm={6} md={6}>
                    <div style={{
                        height: props.height,
                        backgroundImage: props.theme.login_page ? `url(${props.theme.login_page.img.path})` : null,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center"
                    }}>
                    </div>
                </Grid>
            </Hidden>
        </Grid >

    )
}

LoginComponent.propTypes = {
    onSubmitLogin: PropTypes.func.isRequired
}


const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down('xs')]: {
            width: "100%",
        }
    },
    content: {
        textAlign: "center",
    },
    textField: {
        marginBottom: 24
    }
}))

export default LoginComponent
